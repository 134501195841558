@import '../../global.scss';

.intro{
  background-color: white;
  display: flex;
  color: $mainColor;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left{
    flex:0.5;
    overflow: hidden;

    @include mobile {
      width: 100%;
    }

    .imgContainer{
      width: 700px;
      height: 700px;
      background-color: $circle;
      border-radius: 50%;
      border: solid 3px $developer;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;
      overflow:hidden;
     
      @include mobile {
        align-items: flex-start;
        width: 100%;
        height: 100vw;
      }
      
      img{
        margin-left: -70px;
        height: 105%;
        
        @include mobile {
          margin-left: 0px;
          margin-top: -5%;
          height: 120%;
        }
      }
      .notVisible {
        display: none;
      }
    }
  }

  .right{
    flex:0.5;
    position: relative;
    .wrapper{
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        justify-content: start;
        padding-left:  0;
        padding-top: 10%;
        align-items: center;
      }
      h1{
        font-size: 60px;
        margin: 10px 0;
        
        @include mobile {
          font-size: 10vw;
        }
        
      }
      h2{
        font-size: 35px;
        margin: 10px 0;
        
        @include mobile {
          font-size: 8vw;
        }
        
      }
      h3{
        font-size: 30px;
        margin: 10px 0;

        @include mobile {
          font-style: 20px;
        }
        
        .span{
          color: $developer;
        }
        span:nth-child(2) {
            color: black;
            opacity: 1;
            animation: blink 1s infinite;
        }
        @keyframes blink {
          50%{
            opacity: 1;
          }
          100% {
              opacity: 0;
          }
        }
  
      }
    }
    a{
      position: absolute;
      bottom: 10px;
      left: 40%;
      img{
        width: 40px;
        opacity: 0;
        transition: all 2s ease;
        animation: arrowBlink 7s;
      }
      img:hover{
        opacity: 1;
      }
      @keyframes arrowBlink{
        from{
          opacity: 1;
        }
        to{
          opacity: 0;
        }
      }
    }
    .wrapper:hover + a img{
      opacity: 1;
    }
  }
}