@import '../../global.scss';

.portfolio{
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    position: relative;
    height: fit-content !important;
    min-height: 80vh !important;
  }

  h1{
    font-size: 2.1em;
    color: $mainColor;

    @include mobile {
      font-size: 1.6em;
    }
  }

  ul{
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center; 
      margin: 0;
    }

  }
  .container{
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @include mobile {
      width: 100%; 
      height: 90%;
      margin-bottom: 5%;
      align-items: flex-start;
    }
    p{
      font-size: 1.05em ;
    }
    i{
      color: black;
      font-weight: bold;
    }
    .webAppT{
      font-weight: bold;
      margin-bottom: 0px;
    }
    .webAppP{
      padding-left: 0em;
      margin-top: 3px ;
    }

    .noMargin{
      margin: 0px;
      a{
        color: inherit;
      }
    }
    .noMarginBottom{
      margin-bottom: 0px;
    }
    a {
      color: inherit;
    }
    .ubication{
      margin-top: 3%;
      margin-left: -5px;
      a{
        color: inherit;
        text-decoration: none;
      }
      svg{
        margin-bottom: -5px;
        color: $mainColor;
      }
      span{
        margin-left: -3px;
      }
    }
    .logoConteiner{
      position: relative;
      display: flex;
      justify-content: space-around;
      height: 10%;
      margin-top: 5%;

      @include mobile {
        flex-direction: column;
        align-items: center;
        margin-top: -5%;
        height: 35vh;
      }

      .logo{
        height: 100%;
        img{
          height:100%;
          @include mobile{
            margin-top: 0;
          }
        }
        @include mobile{
          height: 12%;
        }
      }

      :nth-child(2){
        height: 80%;
        position: relative; /* enable relative positioning */
        top: 5%; /* move 4th child up by 10px */
        @include mobile{
          height: 10%;
          position: relative; /* enable relative positioning */
          top: 0%; /* move 4th child up by 10px */
        }
      }
      :nth-child(3){
        height: 120%;
        @include mobile{
          height: 15%;
        }
      }
      :nth-child(4){
        height: 120%;
        
        @include mobile{
          height: 15%;
        }
      }
      :nth-child(5){
        height: 115%;
        @include mobile{
          height: 15%;
        }
      }
      :nth-child(6){
        height: 105%;
        @include mobile{
          height: 12%;
        }
      }
    }
  }
}