@import '../../global.scss';
.topbar{
  width: 100%;
  height: 70px;
  background-color: white;
  color: $mainColor;
  position: fixed;
  z-index: 3;
  transition: all 1s ease;
  .wrapper{
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      display: flex;
      align-items: center;
      .logo{
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color:inherit;
        margin-right: 0px;
        @include mobile {
          display: none;
        }
      }
      .itemContainer{
        display: flex;
        justify-content: center;
        margin-left: 40px;

        .icon{
          font-size: 18px;
          margin-right: 5px;
        }
        span{
          font-size: 15px;
          font-weight: 500;
        }
        a{
          text-decoration: none;
          color:inherit;
        }
        @include mobile {
          display: none;
        }
      }
      .cv{
        @include mobile {
          display: flex;
          margin-left: 15px;
          padding-top: 8px;
          .icon{
            font-size: 28px;
          }
          span{
            font-size: 25px;
            font-weight: 500;
          }
        }
      }
      .email{
        display: none;
        @include mobile {
          display: flex;
          margin-left: 5px;
          padding-top: 8px;
          .icon{
            font-size: 28px;
          }
          span{
            font-size: 25px;
            font-weight: 500;
          }
        }
      }
      .itemContainerSocial{
        display: flex;
        justify-content: center;
        margin-left: 40px;

        a{
          text-decoration: none;
          color:inherit;
        }

        @include mobile {
          display: none;
        }

        .icon{
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
    .right{
      .hamburger{
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
      }
      span{
        width: 100%;
        height: 3px;
        background-color: $mainColor;
        transform-origin: left;
        transition: all 1s ease;
      }
    }
  }
  &.topbarActive{
    background-color: $mainColor;
    color: white;
    .hamburger{
      span{
        &:first-child{
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:last-child{
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
} 