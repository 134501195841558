@import '../../../global.scss';

.works{
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include mobile {
   height: 110vh !important;
  }

  .title{
    position: absolute;
    top: 5px;
    @include mobile {
      display: block;
    }
    h1{
      color: white;
      font-size: 5.9vh;
      @include mobile {
      font-size: 1.9em;
    }
    }
  }
  .arrow{
    width: 70px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &.left{
      transform: rotateZ(90deg);
      left: 100px;
    }
    &.right{
      transform: rotateZ(270deg);
      right: 100px;
    }
  }
  .slider{
    height: 62%;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      margin-top: 5vh;
      height: 95vh;
      flex-direction: column;
      justify-content: space-around;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mobile {
        height: 22%;
      }
      .item{
        width: 51.2%;
        height: 100%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        
        @include mobile {
          width: 90%;
          height: 100%;
          margin: 5px 0;
        }


        .left{
          flex: 4;
          height: 100%;
          visibility: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          background-color: rgb(0, 0, 0, 0.5);

          @include mobile {
            flex: 12;
          }
  
          
          .leftContainer{
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: white;
            @include mobile {
              height: 95%;
              justify-content: space-around;
            }

            h2{
              font-size: 3.5vh;
              margin: 0;
            }
            p{
              font-size: 2.3vh;
              @include mobile {
                margin: 0;
              }
            }
            a{
              text-decoration: none;
              color:inherit;
            }
            span{
              font-size: 2.6vh;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
              @include mobile {
                display: none;
              }
            }
          }
        }
        .right{
          flex: 8;
          height: 100%;
          @include mobile {
            flex: 0;
          }
        }
      }
      .item:hover{
        .left{
          visibility: visible;
          animation: fadeIn .8s;
       }
       
       @keyframes fadeIn {
          from {
                opacity: 0;
          }
      
          to {
                opacity: 1;
          }
        }
      }
    }
  }
}