@import '../../../global.scss';
.portfolioContent{
  width: 75%;
  height: 85%;
  border: solid $mainColor 2px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @include mobile {
    margin-top: 8%;
    height: 90%;
  }
  
  .text{
    width: 90%;
    height: 90%;
    @include mobile {
      height: 100%;
      :nth-child(1){
        margin-top: 17px;
      }
    }
    overflow: scroll;
  }
  //This is for hide srcollbar  on different browsers
  .text::-webkit-scrollbar {
    display: none;
  }
  
  .text::-ms-scrollbar {
    display: none;
  }
}