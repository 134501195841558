@import '../../global.scss';

.contact{
  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
    scroll-snap-align: none !important
  }

  .left{
    flex: 1;
    overflow: hidden;

    img{
      height: 100%;
    }
  }
  .right{
    flex: 1;
    display: flex;
    flex-direction:  column;
    align-items: center;
    justify-content: center;

    h2{
      font-size:  30px;
      color: $mainColor;
    }
    form{
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input{
        width: 300px;
        height: 30px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 20px;
        }

      }
      textarea{
        width: 300px;
        height: 200px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 100px;
        }

      }

      button{
        width: 150px;
        height: 30px;
        color: white;
        background-color: $button;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
        border: none;
        &:focus{
          outline: none;
        }
      }
      .ok {
        color: green;
        font-weight: bold;
      }
      .loading{
        color: rgb(131, 131, 3);
        font-weight: bold;
      }
      .error{
        color: red;
        font-weight: bold;
      }
      
    }
  }
}