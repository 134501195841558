
@import '../../../global.scss';

.portfolioList{
  color:black;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 25px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;

  &.active{
    background-color: $mainColor;
    color: white;
  }
}