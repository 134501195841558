@import '../../global.scss';

.testimonials{
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @include mobile {
    position: relative;
    height: 140vh !important;
    justify-content: flex-start;
  }

  .emailCopied{
    position: fixed;
    left: 25px;
    top: 75px;
    z-index: 2;
    padding: 5px;
    border: 1px solid rgba(36, 241, 6, 0.46);
    background-color: rgba(7, 149, 66, 0.12156862745098039);
    box-shadow: 0px 0px 2px #259c08;
    color: #0ad406;
    font-size: 18px;
    transition: all 0.5s ease;
  }
  .nonVisibile{
    position: fixed;
    left: -75px;
    padding: 5px;
    border: 1px solid rgba(36, 241, 6, 0.46);
    background-color: rgba(7, 149, 66, 0.12156862745098039);
    box-shadow: 0px 0px 2px #259c08;
    color: #0ad406;
  }

  h1{
    font-size: 50px;
    color: $mainColor;

    @include mobile {
      height: 5vh;
      font-size: 1.9em;
    }
  }

  .container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include mobile {
      flex-direction: column;
      height: 135vh;
      margin-top: -5%;
    }
  
    .card{
      width: 250px;
      height: 70%;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px black;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;
      margin: 0 5px;

      @include mobile {
        box-sizing: border-box;
        height: 21%;
        margin: 3% 0; 
      }

      &.featured{
        width: 300px;
        height: 75%;
      }

      &:hover{
        transform: scale(1.1);
        margin: 0 30px;
        @include mobile {
          transform: scale(1.05);
        }
      }
      .top{
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
          height: 30%;
         }

        img{
          &.left,
          &.right{
            height: 25px;
          }
          &.user{
            height: 60px;
            width: 60px;
            border-radius: 50%;
            object-fit: cover;
            margin: 0 20px;

            @include mobile {
             width: 30px;
             height: 30px;
            }

          }
          &.left{
            height: 27px;
            cursor: pointer;
          }
        }
      }
      .center{
        padding: 10px;
        border-radius: 10px;
        background-color: rgb(250, 245, 245);
      
        @include mobile {
          height: 40%;
          font-size: 12px;
          padding: 3%;
          margin-top: 4%;
        }

      }
      .bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        @include mobile {
          height: 40%;
        }

        h3{
          margin-bottom: 5px;

          @include mobile {
            font-size: 14px;
        }

        }
        h4{
          color: gray;
          
          @include mobile {
            font-size: 13px;
            margin:4% 0
          }
        }
      }
    }
  }


}