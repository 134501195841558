@import './global.scss';
body {
  margin:0;
}
.app{
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  .sections{
    height: calc(100vh - 70px);
    position: relative;
    top: 70px;
    scroll-behavior: smooth;
    scroll-snap-type: y proximity;
    overflow-y: scroll;
    scrollbar-width: none;

    @include mobile {
      scroll-snap-type: none;
    }

    &::-webkit-scrollbar{
      display: none;
    }
    ;

    > *{
      height: 100%;
      scroll-snap-align: start;
    }
  }
}